<template>
    <div>
        <div class="padding">
            <el-page-header :title="$t('返回')" @back="goBack" :content="$t('练习详情')">
            </el-page-header>
        </div>
        <el-card class="margin-t"  shadow="never">

            <div slot="header" class="flex flex-between pointer flex-v-center pointer">
                <div class="flex flex-v-center flex1">
                    <el-image style="width:80px;height:80px;" :src="$host + type.logo"></el-image>
                    <div class="margin-x flex1">
                        <h3>{{ type.title }}</h3>

                        <div class="margin-t st1">
                            {{$t('日期')}}： {{ day }}
                        </div>
                    </div>
                </div>


                <div class="flex flex-v-center">
                    <div class="center">
                        <div class="st">{{$t('练习题数')}}</div>
                        <h1 class="lt margin-ts">{{ list.length }}</h1>
                    </div>
                    <div class="margin-x" style="height: 50px;width:1px;background-color: #eee;"></div>
                    <div class="center">
                        <div class="st">{{$t('练习次数')}}</div>
                        <h1 class="lt margin-ts">{{ count }}</h1>
                    </div>
                </div>

            </div>
            <div class="document">
                <el-menu default-active="0" router>
                    <el-menu-item :id="source.no" v-for="(source, index1) in list" :key="index1"
                        :index="'/main/pte?tag=' + source.tag + '&no=' + source.no" class="border br margin"
                        style="height:60px">
                        <div class="flex flex-between flex-v-center" style="height:100%">
                            <div class="flex flex-v-center">
                                <span class="margin-x st1 bold">#{{ source.no }} {{ source.title }}</span>
                            </div>
                            <span class="mt">{{$t('练习')}} {{ source.count }}{{$t('次')}} <i class="el-icon-arrow-right"></i></span>
                        </div>
                    </el-menu-item>
                </el-menu>
            </div>
        </el-card>
    </div>
</template>

<script>
export default {
    data() {
        return {
            tag: "",
            day: "",
            count: 0,
            list: []
        };
    },

    computed: {
        type: function () {
            return this.$store.getters.getType(this.tag) || {}
        }
    },

    created() {
        this.tag = this.$route.query.tag;
        this.day = this.$route.query.day;
        this.query();
    },

    methods: {
        goBack() {
            this.$router.back();
        },
        query() {
            this.$http.post("/api/source/record/detail", { day: this.day, tag: this.tag }).then((list) => {
                this.list = list;
                let count = 0;
                list.forEach(item => {
                    count += item.count;
                });
                this.count = count;
            })
        },
    },
};
</script>
<style scoped>
.document>>>.el-card__header {
    padding: 10px 20px;
}
</style>
